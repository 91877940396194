global.is_local = /localhost/.test(window.location.host) ? 1 : 0;
let is_local = global.is_local;


let local = 'http://localhost:6057';
let multerDomain = 'http://localhost:1111';
// local = 'http://212.8.247.141:6057'
let isAqa = /aqa-admin/gi.test(window.location.hostname)
// VIDEO_UPLOAD_DOMAIN: 'http://uploads/itconsult-web.ru',
//     VIDEO_DOMAIN: 'http://uploads/itconsult-web.ru',
let VIDEO_DOMAIN = !!global.is_local ? multerDomain : 'https://uploader.itconsult-web.ru';
let STATIC_DOMAIN = !!global.is_local ? multerDomain : 'https://static.itconsult-web.ru';

window.env = {
    domain: global.is_local ? local : isAqa ? 'https://aqa-api.javacode.ru' :  'https://api-razvitie.itrum.ru',
    VIDEO_DOMAIN_PLAYER: STATIC_DOMAIN,
    VIDEO_DOMAIN: VIDEO_DOMAIN,
    video_domain: VIDEO_DOMAIN,
    title: 'Interview Portal',
    login_title: 'Interview Portal',
    wo_token: false,
    redirect_after_login: '/admin/users',
    isAdmin: true,
    is_local: global.is_local
}

document.title = window.env.title;
